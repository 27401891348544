import { environment } from '../../../environments/environment';
import { EnvironmentKey } from '../../../environments/application-environment';

// TODO: switch to preper logging framework
export function logger(message: string, ...environments: EnvironmentKey[]): void;
export function logger(callback: () => unknown[] | string, ...environments: EnvironmentKey[]): void;

export function logger(subject: string | (() => unknown[] | string), ...environments: EnvironmentKey[]): void {
    if (environments.includes(environment.key)) {
        if (typeof subject === 'string') {
            console.debug(subject);
        } else {
          const itemsToLog = subject();
          if (Array.isArray(itemsToLog)) {
            const serialized = itemsToLog.map((i) => String(i));
            console.debug(...serialized);
          } else {
            console.debug(itemsToLog);
          }
        }
    }
}
